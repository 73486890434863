import React from 'react'
import axios from 'axios'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

export default class GalleryPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      photoIndex: 0,
      isOpen: false,
    }
  }

  componentDidMount() {
    try {
      const WOW = require('wowjs')
      const wow = new WOW.WOW()
      wow.init()
    } catch (e) {
      console.error(e)
    }

    axios
      .get(`${process.env.API_URL}/umbraco/api/gallery/getfull`)
      .then(result => {
        this.setState({
          data: result.data.map(
            model => `${process.env.API_URL}${model.Photo}`
          ),
        })
      })
      .catch(error => {})
  }

  render() {
    const { data, photoIndex, isOpen } = this.state

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Galeria</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section id="about" className="about_us section-padding">
          <div className="container">
            <div className="row">
              <div className="grid">
                {this.state.data.map((galleryImg, idx) => {
                  return (
                    <div
                      key={idx}
                      onClick={() =>
                        this.setState({ photoIndex: idx, isOpen: true })
                      }
                      className="col-md-4 col-sm-4"
                    >
                      <div className="image-wrapper">
                        <img className="img-responsive" src={galleryImg} />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        {isOpen && (
          <Lightbox
            mainSrc={data[photoIndex]}
            nextSrc={data[(photoIndex + 1) % data.length]}
            prevSrc={data[(photoIndex + data.length - 1) % data.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + data.length - 1) % data.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % data.length,
              })
            }
          />
        )}
      </div>
    )
  }
}
